var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { borderRight, borderLeft, borderTop, display, position, space, zIndex, borderColor, borderWidth, borderStyle, width, left, color, } from 'styled-system';
import { getForwardPropOpts } from '../../theme/helpers/forward-props';
import { themeHeight } from '../../theme/system-utilities';
import { Text } from '../Text';
var StoererWrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
StoererWrapper.defaultProps = {
    pb: ['sp_4', '6px', 'sp_8', '10px'],
};
var StoererText = styled(Text, getForwardPropOpts(['color']))(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, position, space, zIndex, color);
StoererText.defaultProps = __assign(__assign({}, Text.defaultProps), { zIndex: 1, px: 'sp_4', color: 'sys.neutral.border.strong', fontWeight: 'bold', fontSize: ['fs_18', 'fs_20', 'fs_24', 'fs_28'], lineHeight: ['lh_22', 'lh_24', 'lh_28', 'lh_32'], position: 'relative', display: 'inline' });
var StoererBorder = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, borderColor, borderWidth, borderStyle, themeHeight, width, zIndex, left, borderRight, borderLeft, borderTop);
StoererBorder.defaultProps = {
    left: 0,
    width: '100%',
    themeHeight: ['sp_12', '14px', 'sp_16', '18px'],
    borderWidth: ['14px', '16px', '20px', '22px'],
    borderStyle: 'solid',
    borderColor: 'sys.neutral.background.default',
    position: 'absolute',
    zIndex: -1,
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
};
var Stoerer = function (_a) {
    var stoererColor = _a.borderColor, textColor = _a.color, text = _a.text, props = __rest(_a, ["borderColor", "color", "text"]);
    return text ? (React.createElement(StoererWrapper, __assign({ "data-testid": "stoerer" }, props),
        React.createElement(StoererText, { color: textColor },
            React.createElement(StoererBorder, { borderColor: stoererColor }),
            text))) : null;
};
export default Stoerer;
var templateObject_1, templateObject_2, templateObject_3;

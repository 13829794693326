import { useCallback } from 'react';
import { PromoEvent } from '../../common/enums/Tracking';
import dispatchTrackingEvent from '../../common/tracking/dispatchTrackingEvent';
import { getCampaignEventData } from '../../common/tracking/campaignEventData';
export var usePromoEvents = function (_a) {
    var dyDecisionId = _a.dyDecisionId, dyVariationId = _a.dyVariationId, placement = _a.placement, name = _a.name, position = _a.position, visitorHash = _a.visitorHash;
    var onPromoView = useCallback(function () {
        dispatchTrackingEvent(getCampaignEventData({
            promoEvent: PromoEvent.PromoView,
            dyDecisionId: dyDecisionId,
            dyVariationId: dyVariationId,
            placement: placement,
            name: name,
            position: position,
            visitorHash: visitorHash,
        }));
    }, [dyDecisionId, dyVariationId, placement, name, visitorHash]);
    var onPromoClick = useCallback(function () {
        dispatchTrackingEvent(getCampaignEventData({
            promoEvent: PromoEvent.PromoClick,
            dyDecisionId: dyDecisionId,
            dyVariationId: dyVariationId,
            placement: placement,
            name: name,
            position: position,
            visitorHash: visitorHash,
        }));
    }, [dyDecisionId, dyVariationId, placement, name, visitorHash]);
    var onPromoClose = useCallback(function () {
        dispatchTrackingEvent(getCampaignEventData({
            promoEvent: PromoEvent.PromoClose,
            dyDecisionId: dyDecisionId,
            dyVariationId: dyVariationId,
            placement: placement,
            name: name,
            position: position,
            visitorHash: visitorHash,
        }));
    }, [dyDecisionId, dyVariationId, placement, name, visitorHash]);
    return {
        onPromoView: onPromoView,
        onPromoClick: onPromoClick,
        onPromoClose: onPromoClose,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventAction, EventCategory, PromoEvent } from '../enums/Tracking';
export var getCampaignEventData = function (_a) {
    var promoEvent = _a.promoEvent, placement = _a.placement, name = _a.name, _b = _a.position, position = _b === void 0 ? 0 : _b, visitorHash = _a.visitorHash, dyDecisionId = _a.dyDecisionId, dyVariationId = _a.dyVariationId;
    return ({
        event: promoEvent,
        eventAction: promoEvent === PromoEvent.PromoClick
            ? EventAction.Click
            : promoEvent === PromoEvent.PromoClose
                ? EventAction.Close
                : EventAction.View,
        eventCategory: EventCategory.Promotion,
        eventData: {
            promotions: [
                __assign(__assign(__assign(__assign(__assign({}, (name && { name: name })), { position: position, placement: placement }), (dyDecisionId && { dyDecisionId: dyDecisionId })), (dyVariationId && { dyVariationId: dyVariationId })), (visitorHash && { visitorHash: visitorHash })),
            ],
        },
    });
};

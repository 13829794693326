var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { display, justifyContent, minHeight, overflow, position, space, width, zIndex, } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import PrismicResponsiveImage from '../../common/components/PrismicResponsiveImage';
import CtaButtonWithDrawer from '../../common/components/Voucher/CtaButtonWithDrawer';
import { getForwardPropOpts } from '../../common/theme/helpers/forward-props';
import { boxSizing, themeBottom, themeTop, } from '../../common/theme/system-utilities';
import TopBannerContentWrapper from './TopBannerContentWrapper';
import { StyledCode, StyledCountDown, StyledLegalDisclaimer, SubTitle, Title, } from './TopTextBanner';
var BannerImageContainer = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  float: left;\n"], ["\n  ", ";\n  ", ";\n  float: left;\n"])), position, overflow);
BannerImageContainer.defaultProps = {
    justifyContent: [null, null, 'center'],
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
};
var ImageWithLegalContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), position);
ImageWithLegalContainer.defaultProps = {
    position: 'relative',
};
var StyledPrismicImage = styled(PrismicResponsiveImage, getForwardPropOpts(['justifyContent'], false))(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), justifyContent, width, minHeight);
StyledPrismicImage.defaultProps = {
    minHeight: ['100px', '150px', '250px', '350px'],
};
var BlockWrapper = styled(Flex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, themeTop, themeBottom, zIndex);
BlockWrapper.defaultProps = {
    alignItems: 'center',
    position: [null, null, 'absolute'],
    px: ['sp_24', null, '52px', 'sp_64'],
    mt: ['-8px', '-24px', 0],
    pt: [null, null, 'sp_20', 'sp_24'],
    maxWidth: [null, null, '380px', '508px'],
    themeTop: 'sp_0',
    themeBottom: 'sp_0',
    zIndex: 1,
};
var ContentWrapper = styled(TopBannerContentWrapper)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), display, space, boxSizing);
ContentWrapper.defaultProps = {
    display: 'block',
    p: ['sp_8', 'sp_16', 'sp_20', 'sp_24'],
    boxSizing: 'border-box',
};
var StyledTitle = styled(Title)(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
StyledTitle.defaultProps = __assign(__assign({}, Title.defaultProps), { mt: 'sp_0', mb: ['sp_4', 'sp_8'], mx: 'sp_0', width: 1 });
var StyledSubTitle = styled(SubTitle)(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
StyledSubTitle.defaultProps = __assign(__assign({}, SubTitle.defaultProps), { mb: 'sp_0', width: 1 });
var Footer = styled(Flex)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
Footer.defaultProps = __assign(__assign({}, Flex.defaultProps), { flexDirection: ['column', 'row', 'column'], justifyContent: ['center', 'space-around', 'center'], alignItems: ['center', null, 'flex-start'], mt: ['sp_0', 'sp_8', 'sp_12', 'sp_16'], width: 1 });
var ImageStyledCountDown = styled(StyledCountDown)(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
ImageStyledCountDown.defaultProps = {
    my: 'sp_4',
};
var StyledTopBannerCtaButton = styled(CtaButtonWithDrawer)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
StyledTopBannerCtaButton.defaultProps = {
    my: 'sp_4',
};
// eslint-disable-next-line complexity
var TopImageBanner = function (props) {
    var bannerStartBgColor = props.bannerStartBgColor, bannerEndBgColor = props.bannerEndBgColor, legalDisclaimerContent = props.legalDisclaimerContent, legalDisclaimerLinkLabel = props.legalDisclaimerLinkLabel, legalDisclaimerLabelColor = props.legalDisclaimerLabelColor, countdownEndDate = props.countdownEndDate, countdownLabel = props.countdownLabel, countdownColor = props.countdownColor, titleColor = props.titleColor, subTitleColor = props.subTitleColor, title = props.title, subTitle = props.subTitle, voucherCode = props.voucherCode, voucherCodeLabel = props.voucherCodeLabel, voucherCodeTextColor = props.voucherCodeTextColor, voucherCodeBoxBgColor = props.voucherCodeBoxBgColor, image = props.image, ctaLabel = props.ctaLabel, ctaColor = props.ctaColor, ctaDisclaimerText = props.ctaDisclaimerText, onPromoClick = props.onPromoClick, onPromoView = props.onPromoView;
    var hasVoucher = title || subTitle || voucherCode || countdownEndDate || ctaLabel;
    var hasDisclaimer = legalDisclaimerContent && legalDisclaimerLinkLabel;
    var hasCtaButton = !voucherCode && ctaLabel && ctaDisclaimerText;
    return (React.createElement(BannerImageContainer, null,
        React.createElement(ImageWithLegalContainer, null,
            React.createElement(StyledPrismicImage, { image: image, highPriority: true, "data-testid": "top-banner-image", justifyContent: "center", width: "100%" }),
            hasDisclaimer && (React.createElement(StyledLegalDisclaimer, { color: legalDisclaimerLabelColor || 'text', content: legalDisclaimerContent, label: legalDisclaimerLinkLabel }))),
        hasVoucher && (React.createElement(BlockWrapper, null,
            React.createElement(ContentWrapper, { onView: onPromoView, startColor: bannerStartBgColor, endColor: bannerEndBgColor },
                title && React.createElement(StyledTitle, { color: titleColor }, title),
                subTitle && (React.createElement(StyledSubTitle, { color: subTitleColor }, subTitle)),
                React.createElement(Footer, null,
                    countdownEndDate && (React.createElement(ImageStyledCountDown, { endDate: countdownEndDate, label: countdownLabel, color: countdownColor })),
                    React.createElement(StyledCode, { onClick: onPromoClick, label: voucherCodeLabel, code: voucherCode, bgColor: voucherCodeBoxBgColor, textColor: voucherCodeTextColor }),
                    hasCtaButton && (React.createElement(StyledTopBannerCtaButton, { ctaLabel: ctaLabel, ctaColor: ctaColor, ctaDisclaimerText: ctaDisclaimerText }))))))));
};
export default TopImageBanner;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { getMediaWidthMax, getMediaWidthMin, getMediaWidthMinMax, } from '../utils/responsive-image/media-condition';
var ImageSetType;
(function (ImageSetType) {
    ImageSetType["Mobile"] = "mobile";
    ImageSetType["Tablet"] = "tablet";
    ImageSetType["Desktop"] = "desktop";
    ImageSetType["LargeDesktop"] = "large_desktop";
})(ImageSetType || (ImageSetType = {}));
var MEDIA_QUERIES_ALL = new Map([
    [ImageSetType.Mobile, getMediaWidthMax(0)],
    [ImageSetType.Tablet, getMediaWidthMinMax(1)],
    [ImageSetType.Desktop, getMediaWidthMinMax(2)],
    [ImageSetType.LargeDesktop, getMediaWidthMin(2)],
]);
var MEDIA_QUERIES_WITHOUT_LARGE = new Map(__spreadArray([], __read(MEDIA_QUERIES_ALL), false).slice(0, -1)).set(ImageSetType.Desktop, getMediaWidthMin(1));
var hasRequiredImageProps = function (image) {
    return image.mobile.x1 && image.tablet.x1 && image.desktop.x1;
};
var getSrcSet = function (_a) {
    var x1 = _a.x1, x2 = _a.x2, x3 = _a.x3;
    return "".concat(x1, " 1x").concat(x2 ? ", ".concat(x2, " 2x") : '').concat(x3 ? ", ".concat(x3, " 3x") : '');
};
var getSources = function (image) {
    var _a;
    var mediaQueries = ((_a = image.large_desktop) === null || _a === void 0 ? void 0 : _a.x1) ? MEDIA_QUERIES_ALL : MEDIA_QUERIES_WITHOUT_LARGE;
    return Object.values(ImageSetType)
        .filter(function (type) { return image[type]; })
        .map(function (type) { return ({
        key: type,
        media: mediaQueries.get(type),
        srcSet: getSrcSet(image[type]),
    }); });
};
var PrismicResponsiveImage = function (_a) {
    var _b;
    var image = _a.image, highPriority = _a.highPriority, className = _a.className;
    if (!hasRequiredImageProps(image)) {
        // eslint-disable-next-line no-console
        console.warn('<PrismicResponsiveImage /> is missing required props');
        return null;
    }
    return (React.createElement("picture", null,
        getSources(image).map(function (_a) {
            var key = _a.key, media = _a.media, srcSet = _a.srcSet;
            return (React.createElement("source", { key: key, media: media, srcSet: srcSet }));
        }),
        React.createElement("img", __assign({ src: image[ImageSetType.Mobile].x1, alt: (_b = image.alt) !== null && _b !== void 0 ? _b : '', className: className }, (highPriority && { fetchpriority: 'high' })))));
};
export default PrismicResponsiveImage;

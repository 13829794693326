import { breakpoints } from '../../theme/theme';
var getDecimalInt = function (breakpoint) { return parseInt(breakpoint, 10); };
var getBreakpointEdge = function (breakpoint) {
    return "".concat(getDecimalInt(breakpoint) - 1, "px");
};
export var getMediaWidthMin = function (index) {
    return "(min-width: ".concat(breakpoints[index], ")");
};
export var getMediaWidthMax = function (index) {
    return "(max-width: ".concat(getBreakpointEdge(breakpoints[index]), ")");
};
export var getMediaWidthMinMax = function (index) {
    return "".concat(getMediaWidthMin(index - 1), " and ").concat(getMediaWidthMax(index));
};
var getMediaMinResolution = function (pixelRatio) {
    return "(min-resolution: ".concat(pixelRatio - 0.5, "dppx)");
};
var getMediaMaxResolution = function (pixelRatio) {
    return "(max-resolution: ".concat(pixelRatio + 0.5, "dppx)");
};
export var getMediaResolutionMinMax = function (pixelRatio) {
    var condition = '';
    if (pixelRatio > 1) {
        condition += "".concat(getMediaMinResolution(pixelRatio), " and ");
    }
    condition += getMediaMaxResolution(pixelRatio);
    return condition;
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import styled from '@emotion/styled';
import { alignItems, display, justifyContent, size, backgroundColor, border, space, themeGet, } from 'styled-system';
import { cursor, fill, outline } from '../theme/system-utilities';
import { dispatchTrackingEventGA4 } from '../tracking/dispatchTrackingEventGA4';
import { Element } from '../types/tracking';
import Icon from './Icon';
var ICON_SIZES = [24, null, null, 32];
var Button = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:not(:focus) {\n    ", ";\n  }\n\n  &:hover > svg {\n    fill: ", ";\n  }\n\n  &:active > svg {\n    fill: ", ";\n  }\n\n  &:focus-visible {\n    border-radius: 100%;\n    outline-offset: -6px;\n    outline: 2px solid ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:not(:focus) {\n    ", ";\n  }\n\n  &:hover > svg {\n    fill: ", ";\n  }\n\n  &:active > svg {\n    fill: ", ";\n  }\n\n  &:focus-visible {\n    border-radius: 100%;\n    outline-offset: -6px;\n    outline: 2px solid ", ";\n  }\n"])), display, alignItems, justifyContent, backgroundColor, border, space, cursor, outline, themeGet('colors.sys.neutral.icon.hover'), themeGet('colors.sys.primary.background.hover'), themeGet('colors.sys.secondary.border.focus'));
Button.defaultProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    p: 'sp_0',
    type: 'button',
    cursor: 'pointer',
    outline: 'none',
};
var InfoIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), size, space, fill);
InfoIcon.defaultProps = {
    size: ICON_SIZES,
    name: 'info',
    fill: 'sys.neutral.icon.default',
};
var InfoButton = function (_a) {
    var onClick = _a.onClick, className = _a.className, iconProps = _a.iconProps, eventName = _a.eventName, ariaLabel = _a.ariaLabel, trackingParams = _a.trackingParams;
    var handleClick = function (event) {
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
        if (eventName && (trackingParams === null || trackingParams === void 0 ? void 0 : trackingParams.icon_text)) {
            dispatchTrackingEventGA4(__assign({ event: eventName, element: Element.Icon }, trackingParams));
        }
    };
    return (React.createElement(Button, { className: className, onClick: handleClick, "data-testid": "info-button", "aria-label": ariaLabel !== null && ariaLabel !== void 0 ? ariaLabel : 'Info' },
        React.createElement(InfoIcon, __assign({}, iconProps))));
};
export default memo(InfoButton);
var templateObject_1, templateObject_2;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { usePromoEvents } from '../../common/hooks/dynamic-yield';
import TopImageBanner from './TopImageBanner';
import TopTextBanner from './TopTextBanner';
var TopBanner = function (_a) {
    var name = _a.name, placement = _a.placement, dyDecisionId = _a.dyDecisionId, dyVariationId = _a.dyVariationId, image = _a.image, props = __rest(_a, ["name", "placement", "dyDecisionId", "dyVariationId", "image"]);
    var _b = usePromoEvents({
        dyDecisionId: dyDecisionId,
        dyVariationId: dyVariationId,
        placement: placement,
        name: name,
    }), onPromoView = _b.onPromoView, onPromoClick = _b.onPromoClick;
    return image ? (React.createElement(TopImageBanner, __assign({ onPromoView: onPromoView, onPromoClick: onPromoClick, image: image }, props))) : (React.createElement(TopTextBanner, __assign({ onPromoView: onPromoView, onPromoClick: onPromoClick }, props)));
};
export default TopBanner;

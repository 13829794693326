var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, alignSelf, color, flex, flexDirection, fontSize, justifyContent, lineHeight, maxWidth, overflow, position, space, textAlign, width, zIndex, } from 'styled-system';
import CountDown from '../../common/components/CountDown';
import { Flex } from '../../common/components/Flex';
import LegalDisclaimer from '../../common/components/LegalDisclaimer';
import { Heading, Text } from '../../common/components/Text';
import { Code, Stoerer } from '../../common/components/Voucher';
import CtaButtonWithDrawer from '../../common/components/Voucher/CtaButtonWithDrawer';
import { LEGAL_DISCLAIMER_BANNER_Z_INDEX } from '../../common/constants/z-index';
import { boxSizing, themeBottom, themeRight, } from '../../common/theme/system-utilities';
import TopBannerContentWrapper from './TopBannerContentWrapper';
var TITLES_MAX_WIDTH = {
    maxWidth: ['100%', null, '429px', '569px'],
};
var ContentWrapper = styled(TopBannerContentWrapper)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, alignItems, flexDirection, position, space, boxSizing);
ContentWrapper.defaultProps = {
    position: 'relative',
    alignItems: 'center',
    flexDirection: ['column', null, 'row'],
    pl: ['sp_8', 'sp_24', 'sp_48', 'sp_64'],
    pr: ['sp_8', 'sp_24'],
    py: ['sp_8', 'sp_12', 'sp_24'],
    boxSizing: 'border-box',
};
var StyledStoerer = styled(Stoerer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), flex, textAlign, space, color);
StyledStoerer.defaultProps = {
    flex: 'none',
    textAlign: 'center',
    my: 'sp_4',
    mr: [null, null, 'sp_48'],
};
var Titles = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), maxWidth);
Titles.defaultProps = {
    flexDirection: ['column', null, 'row'],
    flexGrow: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: ['center', null, 'flex-start'],
    mr: [null, null, 'sp_48'],
};
export var Title = styled(Heading)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), color, width);
Title.defaultProps = __assign(__assign({}, Heading.defaultProps), { 'data-testid': 'title', as: 'h2', mt: ['sp_8', 'sp_12', 'sp_0'], mb: ['sp_4', null, null, 'sp_8'], color: 'sys.primary.background.default', textAlign: ['center', null, 'left'], fontSize: ['fs_18', 'fs_24', null, 'fs_28'], lineHeight: ['lh_22', 'lh_28', null, 'lh_32'], mr: [null, null, 'sp_24'], width: [1, null, 'auto'] });
export var SubTitle = styled(Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), overflow, color, width);
SubTitle.defaultProps = __assign(__assign({}, Text.defaultProps), { 'data-testid': 'subtitle', as: 'p', overflow: 'hidden', textAlign: ['center', null, 'left'], fontSize: ['fs_16', 'fs_18', null, 'fs_20'], lineHeight: ['lh_20', 'lh_22', null, 'lh_24'], mb: ['sp_8', 'sp_12', 'sp_0'], mt: 0, width: [1, null, 'auto'] });
var Footer = styled(Flex)(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
Footer.defaultProps = {
    flexDirection: ['column', 'row'],
    justifyContent: ['center', 'space-around', 'flex-start'],
    alignItems: 'center',
    flexGrow: 2,
    flexWrap: 'wrap',
    width: [1, null, 'auto'],
};
export var StyledCountDown = styled(CountDown)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  > p,\n  > div {\n    margin-bottom: 0;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  > p,\n  > div {\n    margin-bottom: 0;\n  }\n"])), justifyContent, space, fontSize, lineHeight, color);
StyledCountDown.defaultProps = {
    color: 'sys.neutral.border.strong',
    borderColor: 'sys.neutral.border.strong',
    justifyContent: 'center',
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: ['lh_20', null, null, 'lh_22'],
    mr: ['sp_0', null, 'sp_24', 'sp_48'],
    my: 'sp_8',
};
export var StyledCode = styled(Code)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  p {\n    ", ";\n    ", ";\n  }\n"], ["\n  p {\n    ", ";\n    ", ";\n  }\n"])), fontSize, lineHeight);
StyledCode.defaultProps = {
    fontSize: ['fs_14', 'fs_16', null, 'fs_18'],
    lineHeight: '26px',
    py: 'sp_4',
    px: 'sp_8',
    my: 'sp_8',
};
var StyledTopBannerCtaButton = styled(CtaButtonWithDrawer)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
StyledTopBannerCtaButton.defaultProps = __assign(__assign({}, CtaButtonWithDrawer.defaultProps), { my: ['sp_0', null, 'sp_8'] });
export var StyledLegalDisclaimer = styled(LegalDisclaimer)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, position, zIndex, themeBottom, themeRight, alignSelf);
StyledLegalDisclaimer.defaultProps = {
    position: 'absolute',
    zIndex: LEGAL_DISCLAIMER_BANNER_Z_INDEX,
    themeRight: 'sp_0',
    themeBottom: ['sp_0', null, 'sp_4'],
    p: 'sp_8',
};
// eslint-disable-next-line complexity
var TopTextBanner = function (props) {
    var bannerStartBgColor = props.bannerStartBgColor, bannerEndBgColor = props.bannerEndBgColor, legalDisclaimerContent = props.legalDisclaimerContent, legalDisclaimerLinkLabel = props.legalDisclaimerLinkLabel, legalDisclaimerLabelColor = props.legalDisclaimerLabelColor, countdownEndDate = props.countdownEndDate, countdownLabel = props.countdownLabel, countdownColor = props.countdownColor, titleColor = props.titleColor, subTitleColor = props.subTitleColor, title = props.title, subTitle = props.subTitle, voucherCode = props.voucherCode, voucherCodeLabel = props.voucherCodeLabel, voucherCodeTextColor = props.voucherCodeTextColor, voucherCodeBoxBgColor = props.voucherCodeBoxBgColor, storer = props.storer, storerTextColor = props.storerTextColor, storerLineColor = props.storerLineColor, ctaLabel = props.ctaLabel, ctaColor = props.ctaColor, ctaDisclaimerText = props.ctaDisclaimerText, onPromoView = props.onPromoView, onPromoClick = props.onPromoClick;
    var hasFooter = countdownEndDate || voucherCode || ctaLabel;
    var hasTitles = title || subTitle;
    var hasDisclaimer = legalDisclaimerContent && legalDisclaimerLinkLabel;
    var hasCtaButton = !voucherCode && ctaLabel && ctaDisclaimerText;
    var hasStorer = !!storer;
    var hasContent = hasFooter || hasTitles || hasCtaButton || hasStorer;
    if (!hasContent) {
        return null;
    }
    return (React.createElement(ContentWrapper, { startColor: bannerStartBgColor, endColor: bannerEndBgColor, onView: onPromoView },
        hasDisclaimer && (React.createElement(StyledLegalDisclaimer, { color: legalDisclaimerLabelColor || 'text', content: legalDisclaimerContent, label: legalDisclaimerLinkLabel })),
        React.createElement(StyledStoerer, { text: storer, borderColor: storerLineColor, color: storerTextColor }),
        hasTitles && (React.createElement(Titles, __assign({}, (hasFooter && TITLES_MAX_WIDTH)),
            title && React.createElement(Title, { color: titleColor }, title),
            subTitle && React.createElement(SubTitle, { color: subTitleColor }, subTitle))),
        hasFooter && (React.createElement(Footer, null,
            countdownEndDate && (React.createElement(StyledCountDown, { endDate: countdownEndDate, label: countdownLabel, color: countdownColor })),
            React.createElement(StyledCode, { code: voucherCode, label: voucherCodeLabel, bgColor: voucherCodeBoxBgColor, textColor: voucherCodeTextColor, onClick: onPromoClick }),
            hasCtaButton && (React.createElement(StyledTopBannerCtaButton, { ctaLabel: ctaLabel, ctaColor: ctaColor, ctaDisclaimerText: ctaDisclaimerText }))))));
};
export default TopTextBanner;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

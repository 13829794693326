var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useIsObserved } from '../../hooks/intersection-observer';
import { Flex } from '../Flex';
import GradientBlock from '../GradientBlock';
var VoucherContent = styled(GradientBlock)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
VoucherContent.defaultProps = __assign(__assign({}, GradientBlock.defaultProps), { color: 'sys.neutral.border.strong', alignItems: 'center', flexWrap: 'wrap', px: 'sp_16', py: 'sp_8', mb: ['sp_16', 'sp_20', null, 'sp_24'] });
var Block = function (_a) {
    var onView = _a.onView, startColor = _a.startColor, endColor = _a.endColor, children = _a.children, disclaimer = _a.disclaimer, displayFlexRow = _a.displayFlexRow, isVertical = _a.isVertical, isContentCenterAligned = _a.isContentCenterAligned, withBorderRadius = _a.withBorderRadius, props = __rest(_a, ["onView", "startColor", "endColor", "children", "disclaimer", "displayFlexRow", "isVertical", "isContentCenterAligned", "withBorderRadius"]);
    var _b = __read(useIsObserved(), 2), ref = _b[0], isObserved = _b[1];
    useEffect(function () {
        if (isObserved) {
            onView === null || onView === void 0 ? void 0 : onView();
        }
    }, [isObserved]);
    return (React.createElement(Flex, { flexDirection: displayFlexRow ? 'row' : 'column', "data-testid": "voucher", ref: ref },
        React.createElement(VoucherContent, __assign({}, props, { startColor: startColor, endColor: endColor }, (isVertical && {
            flexDirection: 'column',
            alignItems: isContentCenterAligned ? 'center' : 'flex-start',
        }), (displayFlexRow && {
            bg: 'transparent',
            px: 'sp_0',
            py: 'sp_0',
        }), (withBorderRadius && {
            borderRadius: 'medium',
        })), children),
        disclaimer));
};
export default Block;
var templateObject_1;
